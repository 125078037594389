// // <!-- Hotjar Tracking Code for https://bentec.dev -->
//
import VueHotjar from 'vue-hotjar-next';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueHotjar, {
    id: 2890375,
    isProduction: import.meta.env.PROD,
  });
});
