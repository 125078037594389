import { default as indexXsEmmZlmdjMeta } from "/opt/build/repo/pages/app/index/dashboard/index.vue?macro=true";
import { default as GalaxyGeneratorJ6P2qepAvRMeta } from "/opt/build/repo/pages/app/index/tools/GalaxyGenerator.vue?macro=true";
import { default as QrCodeGeneratorOtMIKyB9uSMeta } from "/opt/build/repo/pages/app/index/tools/QrCodeGenerator.vue?macro=true";
import { default as indexAJTSoDMLRwMeta } from "/opt/build/repo/pages/app/index.vue?macro=true";
import { default as loginJiXA6yUd8bMeta } from "/opt/build/repo/pages/auth/index/login.vue?macro=true";
import { default as _91token_93SF4HIjpWrgMeta } from "/opt/build/repo/pages/auth/index/resetPassword/[token].vue?macro=true";
import { default as indexcPHnKKMm3hMeta } from "/opt/build/repo/pages/auth/index/resetPassword/index.vue?macro=true";
import { default as _91token_93LZrxohTqEdMeta } from "/opt/build/repo/pages/auth/index/signup/[token].vue?macro=true";
import { default as indexPcXHZT2narMeta } from "/opt/build/repo/pages/auth/index/signup/index.vue?macro=true";
import { default as signup0H8tBLHKvEMeta } from "/opt/build/repo/pages/auth/index/signup.vue?macro=true";
import { default as indexzXuJ7S0F7fMeta } from "/opt/build/repo/pages/auth/index.vue?macro=true";
import { default as _91name_93xH4LJnvuxXMeta } from "/opt/build/repo/pages/blog/[name].vue?macro=true";
import { default as indexmFsYN4t0XfMeta } from "/opt/build/repo/pages/blog/index.vue?macro=true";
import { default as index8KdCOhjwhDMeta } from "/opt/build/repo/pages/chat/index.vue?macro=true";
import { default as CookievlZoXGfPNNMeta } from "/opt/build/repo/pages/Cookie.vue?macro=true";
import { default as _91id_939fEof6KVWwMeta } from "/opt/build/repo/pages/index/project/[name]/[id].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as Privacy00Qwyv1pTxMeta } from "/opt/build/repo/pages/Privacy.vue?macro=true";
import { default as _91id_93JLqVapO2UnMeta } from "/opt/build/repo/pages/project/[name]/[id].vue?macro=true";
export default [
  {
    name: indexAJTSoDMLRwMeta?.name ?? "app",
    path: indexAJTSoDMLRwMeta?.path ?? "/app",
    meta: indexAJTSoDMLRwMeta || {},
    alias: indexAJTSoDMLRwMeta?.alias || [],
    redirect: indexAJTSoDMLRwMeta?.redirect,
    component: () => import("/opt/build/repo/pages/app/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexXsEmmZlmdjMeta?.name ?? "app-index-dashboard",
    path: indexXsEmmZlmdjMeta?.path ?? "dashboard",
    meta: indexXsEmmZlmdjMeta || {},
    alias: indexXsEmmZlmdjMeta?.alias || [],
    redirect: indexXsEmmZlmdjMeta?.redirect,
    component: () => import("/opt/build/repo/pages/app/index/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: GalaxyGeneratorJ6P2qepAvRMeta?.name ?? "app-index-tools-GalaxyGenerator",
    path: GalaxyGeneratorJ6P2qepAvRMeta?.path ?? "tools/GalaxyGenerator",
    meta: GalaxyGeneratorJ6P2qepAvRMeta || {},
    alias: GalaxyGeneratorJ6P2qepAvRMeta?.alias || [],
    redirect: GalaxyGeneratorJ6P2qepAvRMeta?.redirect,
    component: () => import("/opt/build/repo/pages/app/index/tools/GalaxyGenerator.vue").then(m => m.default || m)
  },
  {
    name: QrCodeGeneratorOtMIKyB9uSMeta?.name ?? "app-index-tools-QrCodeGenerator",
    path: QrCodeGeneratorOtMIKyB9uSMeta?.path ?? "tools/QrCodeGenerator",
    meta: QrCodeGeneratorOtMIKyB9uSMeta || {},
    alias: QrCodeGeneratorOtMIKyB9uSMeta?.alias || [],
    redirect: QrCodeGeneratorOtMIKyB9uSMeta?.redirect,
    component: () => import("/opt/build/repo/pages/app/index/tools/QrCodeGenerator.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexzXuJ7S0F7fMeta?.name ?? "auth",
    path: indexzXuJ7S0F7fMeta?.path ?? "/auth",
    meta: indexzXuJ7S0F7fMeta || {},
    alias: indexzXuJ7S0F7fMeta?.alias || [],
    redirect: indexzXuJ7S0F7fMeta?.redirect,
    component: () => import("/opt/build/repo/pages/auth/index.vue").then(m => m.default || m),
    children: [
  {
    name: loginJiXA6yUd8bMeta?.name ?? "auth-index-login",
    path: loginJiXA6yUd8bMeta?.path ?? "login",
    meta: loginJiXA6yUd8bMeta || {},
    alias: loginJiXA6yUd8bMeta?.alias || [],
    redirect: loginJiXA6yUd8bMeta?.redirect,
    component: () => import("/opt/build/repo/pages/auth/index/login.vue").then(m => m.default || m)
  },
  {
    name: _91token_93SF4HIjpWrgMeta?.name ?? "auth-index-resetPassword-token",
    path: _91token_93SF4HIjpWrgMeta?.path ?? "resetPassword/:token()",
    meta: _91token_93SF4HIjpWrgMeta || {},
    alias: _91token_93SF4HIjpWrgMeta?.alias || [],
    redirect: _91token_93SF4HIjpWrgMeta?.redirect,
    component: () => import("/opt/build/repo/pages/auth/index/resetPassword/[token].vue").then(m => m.default || m)
  },
  {
    name: indexcPHnKKMm3hMeta?.name ?? "auth-index-resetPassword",
    path: indexcPHnKKMm3hMeta?.path ?? "resetPassword",
    meta: indexcPHnKKMm3hMeta || {},
    alias: indexcPHnKKMm3hMeta?.alias || [],
    redirect: indexcPHnKKMm3hMeta?.redirect,
    component: () => import("/opt/build/repo/pages/auth/index/resetPassword/index.vue").then(m => m.default || m)
  },
  {
    name: signup0H8tBLHKvEMeta?.name ?? undefined,
    path: signup0H8tBLHKvEMeta?.path ?? "signup",
    meta: signup0H8tBLHKvEMeta || {},
    alias: signup0H8tBLHKvEMeta?.alias || [],
    redirect: signup0H8tBLHKvEMeta?.redirect,
    component: () => import("/opt/build/repo/pages/auth/index/signup.vue").then(m => m.default || m),
    children: [
  {
    name: _91token_93LZrxohTqEdMeta?.name ?? "auth-index-signup-token",
    path: _91token_93LZrxohTqEdMeta?.path ?? ":token()",
    meta: _91token_93LZrxohTqEdMeta || {},
    alias: _91token_93LZrxohTqEdMeta?.alias || [],
    redirect: _91token_93LZrxohTqEdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/auth/index/signup/[token].vue").then(m => m.default || m)
  },
  {
    name: indexPcXHZT2narMeta?.name ?? "auth-index-signup",
    path: indexPcXHZT2narMeta?.path ?? "",
    meta: indexPcXHZT2narMeta || {},
    alias: indexPcXHZT2narMeta?.alias || [],
    redirect: indexPcXHZT2narMeta?.redirect,
    component: () => import("/opt/build/repo/pages/auth/index/signup/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: _91name_93xH4LJnvuxXMeta?.name ?? "blog-name",
    path: _91name_93xH4LJnvuxXMeta?.path ?? "/blog/:name()",
    meta: _91name_93xH4LJnvuxXMeta || {},
    alias: _91name_93xH4LJnvuxXMeta?.alias || [],
    redirect: _91name_93xH4LJnvuxXMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[name].vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog",
    path: indexmFsYN4t0XfMeta?.path ?? "/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: index8KdCOhjwhDMeta?.name ?? "chat",
    path: index8KdCOhjwhDMeta?.path ?? "/chat",
    meta: index8KdCOhjwhDMeta || {},
    alias: index8KdCOhjwhDMeta?.alias || [],
    redirect: index8KdCOhjwhDMeta?.redirect,
    component: () => import("/opt/build/repo/pages/chat/index.vue").then(m => m.default || m)
  },
  {
    name: CookievlZoXGfPNNMeta?.name ?? "Cookie",
    path: CookievlZoXGfPNNMeta?.path ?? "/Cookie",
    meta: CookievlZoXGfPNNMeta || {},
    alias: CookievlZoXGfPNNMeta?.alias || [],
    redirect: CookievlZoXGfPNNMeta?.redirect,
    component: () => import("/opt/build/repo/pages/Cookie.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_939fEof6KVWwMeta?.name ?? "index-project-name-id",
    path: _91id_939fEof6KVWwMeta?.path ?? "project/:name()/:id()",
    meta: _91id_939fEof6KVWwMeta || {},
    alias: _91id_939fEof6KVWwMeta?.alias || [],
    redirect: _91id_939fEof6KVWwMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index/project/[name]/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: Privacy00Qwyv1pTxMeta?.name ?? "Privacy",
    path: Privacy00Qwyv1pTxMeta?.path ?? "/Privacy",
    meta: Privacy00Qwyv1pTxMeta || {},
    alias: Privacy00Qwyv1pTxMeta?.alias || [],
    redirect: Privacy00Qwyv1pTxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/Privacy.vue").then(m => m.default || m)
  },
  {
    name: _91id_93JLqVapO2UnMeta?.name ?? "project-name-id",
    path: _91id_93JLqVapO2UnMeta?.path ?? "/project/:name()/:id()",
    meta: _91id_93JLqVapO2UnMeta || {},
    alias: _91id_93JLqVapO2UnMeta?.alias || [],
    redirect: _91id_93JLqVapO2UnMeta?.redirect,
    component: () => import("/opt/build/repo/pages/project/[name]/[id].vue").then(m => m.default || m)
  }
]