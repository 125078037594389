import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  RadialLinearScale,
  LinearScale,
  ArcElement,
} from 'chart.js';
import 'chart.js/auto';

export default defineNuxtPlugin((nuxtApp) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    RadialLinearScale,
    Title,
    Tooltip,
    Legend,
  );
});

// import VueApexCharts from 'vue3-apexcharts';
// export default defineNuxtPlugin((nuxtApp) => {
//   nuxtApp.vueApp.use(VueApexCharts);
// });
