<script setup lang="ts">
import sprite from '@/assets/sprite.svg';

interface Props {
  icon: string;
  size?: 'small' | 'normal' | 'large';
  styling?: 'primary' | 'secondary' | 'tertiary' | 'none';
  useHover?: boolean;
  tag?: 'div' | 'span' | 'button';
}

const props = withDefaults(defineProps<Props>(), {
  size: 'small',
  styling: 'none',
  useHover: false,
  tag: 'div',
});

const svgIcon = computed(() => {
  return {
    'xlink:href': sprite + '#' + props.icon,
  };
});
</script>

<template>
  <component
    :is="tag"
    class="icon-container"
    :class="[
      {
        small: 'size size--small',
        normal: 'size size--normal',
        large: 'size size--large',
        xLarge: 'size size--xLarge',
      }[size],
      {
        none: '',
        primary: 'styling styling--primary',
        secondary: 'styling styling--secondary',
        tertiary: 'styling styling--tertiary',
        roundWhite: 'styling--round-white',
      }[styling],
      { 'hover-effects': useHover },
    ]"
  >
    <svg class="icon-container__icon">
      <use v-bind="svgIcon" />
    </svg>
  </component>
</template>

<style scoped lang="scss">
button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.icon-container {
  display: inline-block;

  &.hover-effects {
    transition: 250ms transform ease-in-out;
    backface-visibility: hidden;

    &::before,
    &::after {
      transition: all 0.5s ease-in-out;
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - 1rem);
      left: calc(50% - 1rem);
      width: 1rem;
      height: 1rem;
      border-radius: 100%;
      background-color: var(--color-white);
      box-shadow:
        0 0 10px 5px var(--color-white),
        0 0 20px 10px var(--color-primary);
      opacity: 0;
    }

    &:hover {
      //transform: scale(1.1);
      animation: spin 20s linear infinite;

      .icon-container__icon {
        animation: spin-counter 20s linear infinite;
      }

      &::before {
        transform: translateX(-3rem);
        opacity: 1;
      }

      &::after {
        transform: translateX(4rem);
        opacity: 1;
      }
    }
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes spin-counter {
    100% {
      transform: rotate(-360deg);
    }
  }

  &.size {
    &--small {
      height: 3rem;
      width: 3rem;
    }

    &--normal {
      height: 4.4rem;
      width: 4.4rem;
    }

    &--large {
      height: 5.8rem;
      width: 5.8rem;
    }

    &--xLarge {
      height: 7.8rem;
      width: 7.8rem;
    }
  }

  // Styling
  &.styling {
    border-radius: 100%;
    padding: 0.9rem;
    height: 4rem;
    width: 4rem;
    color: white;

    &--primary {
      background-color: var(--color-primary);
      box-shadow:
        inset 0 0 7px 3px var(--color-primary-tint-200),
        0 0 4px 4px var(--color-primary-tint-400),
        0 0 6px 6px var(--color-primary-tint-700),
        0 0 20px 8px var(--color-primary-tint-800--transparent);
    }

    &--secondary {
      background-color: var(--color-secondary);
    }

    &--tertiary {
      background-color: var(--color-tertiary);
    }
  }

  // Icon SVG
  &__icon {
    width: 100%;
    height: 100%;
  }
}
</style>
