import revive_payload_client_7D0ms4WFZA from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_rollup@2.79.1_sass@1.70.0_typescript@5.3.3_vite@5.1.1_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_MpZ1q04Ret from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_rollup@2.79.1_sass@1.70.0_typescript@5.3.3_vite@5.1.1_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_b6Ajg6OuMH from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_rollup@2.79.1_sass@1.70.0_typescript@5.3.3_vite@5.1.1_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_PSlQakU5J4 from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.9_@nuxt+devtools@1.0.6_@vue+compiler-core@3.4.18_nuxt@3.10.1_postcss@8.4_ikbrv3wydgznalmyktxaqrusde/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yPWmrxUFQ9 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_rollup@2.79.1_sass@1.70.0_typescript@5.3.3_vite@5.1.1_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_0Zf31QU6EM from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_rollup@2.79.1_sass@1.70.0_typescript@5.3.3_vite@5.1.1_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_Pyy2BFYgTt from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@2.79.1_typescript@5.3.3_vue@3.4.18/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_FGNmFxWDEz from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_rollup@2.79.1_sass@1.70.0_typescript@5.3.3_vite@5.1.1_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_r2s14Uy4Ew from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.8_@lezer+common@1.2.1_@nuxt+devtools@1.0.6_@unhead+shared@1.8.10_@vue+co_jaqihpoyyeoz3dqw5fvxxs7zm4/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_K9q53ruCRm from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.8_@lezer+common@1.2.1_@nuxt+devtools@1.0.6_@unhead+shared@1.8.10_@vue+co_jaqihpoyyeoz3dqw5fvxxs7zm4/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_TN2fLjjefy from "/opt/build/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.4_@nuxt+devtools@1.0.6_@vue+compiler-core@3.4.18_nuxt@3.10.1_po_wmrl66tsjgmxahobibg6fimkky/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_muo7FZ8Ngi from "/opt/build/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.4_@nuxt+devtools@1.0.6_@vue+compiler-core@3.4.18_nuxt@3.10.1_po_wmrl66tsjgmxahobibg6fimkky/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import floating_vue_EIcJ7xiw0h from "/opt/build/repo/.nuxt/floating-vue.mjs";
import pwa_client_DeWjxQokWM from "/opt/build/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.3.6_rollup@2.79.1_vite@5.1.1_workbox-build@7.0.0_workbox-window@7.0.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import chunk_reload_client_Ge3ydsvzQ7 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_rollup@2.79.1_sass@1.70.0_typescript@5.3.3_vite@5.1.1_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_nuxt3_vfGLrIY7X6 from "/opt/build/repo/node_modules/.pnpm/nuxt-mail@4.0.2_rollup@2.79.1/node_modules/nuxt-mail/dist/plugin-nuxt3.js";
import plugin_ZYZDYNylra from "/opt/build/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_pinia@2.1.7_rollup@2.79.1/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import chartjs_1wC9lAM0Tp from "/opt/build/repo/plugins/chartjs.ts";
import hotjar_client_HJG8no80GI from "/opt/build/repo/plugins/hotjar.client.ts";
import i18n_VfGcjrvSkj from "/opt/build/repo/plugins/i18n.ts";
import maska_UHaKf2z1iQ from "/opt/build/repo/plugins/maska.ts";
import tippy_xFBYqfAPCu from "/opt/build/repo/plugins/tippy.ts";
import vue_carousel_CfSAuRs5Fd from "/opt/build/repo/plugins/vue-carousel.ts";
export default [
  revive_payload_client_7D0ms4WFZA,
  unhead_MpZ1q04Ret,
  router_b6Ajg6OuMH,
  _0_siteConfig_PSlQakU5J4,
  payload_client_yPWmrxUFQ9,
  check_outdated_build_client_0Zf31QU6EM,
  plugin_vue3_Pyy2BFYgTt,
  components_plugin_KR1HBZs4kY,
  prefetch_client_FGNmFxWDEz,
  titles_r2s14Uy4Ew,
  defaults_K9q53ruCRm,
  siteConfig_TN2fLjjefy,
  inferSeoMetaPlugin_muo7FZ8Ngi,
  floating_vue_EIcJ7xiw0h,
  pwa_client_DeWjxQokWM,
  chunk_reload_client_Ge3ydsvzQ7,
  plugin_nuxt3_vfGLrIY7X6,
  plugin_ZYZDYNylra,
  chartjs_1wC9lAM0Tp,
  hotjar_client_HJG8no80GI,
  i18n_VfGcjrvSkj,
  maska_UHaKf2z1iQ,
  tippy_xFBYqfAPCu,
  vue_carousel_CfSAuRs5Fd
]